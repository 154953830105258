import Logo from "./assets/logo.png";
//
import "./App.css";

function App() {
  return (
    <div className="app">
      <video loop autoPlay muted>
        <source src={require('./assets/video.mp4')} type="video/mp4" />
      </video>
      <div className="wrapp">
        <img src={Logo} />
      </div>
    </div>
  );
}

export default App;
